<template>
  <section>
    <v-row>
      <v-col cols="12" xl="8" lg="11">
        <div class="text-h5 ml-2 mb-5">Manage Users</div>
        <v-row>
          <v-col cols="12" lg="10">
            <Table :data="students.data" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Table from "../components/student/Table.vue";
export default {
  components: {
    Table,
  },
  computed: {
    ...mapState("teacher", {
      students: (state) => state.students,
    }),
  },
  created() {
    this.getStudent();
  },
  methods: {
    ...mapActions("teacher", ["getStudent"]),
    ...mapMutations("teacher", ["studentsMutation"]),
  },
  beforeDestroy() {
    this.studentsMutation([]);
  },
};
</script>

<style>
</style>