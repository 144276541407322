<template>
  <v-simple-table fixed-header>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left caption" width="10%">Image</th>
          <th class="text-left caption" width="30%">Email</th>
          <th class="text-left caption" width="30%">Name</th>
          <th class="text-left caption" width="20%">Progress</th>
          <th class=""></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id">
          <td>
            <v-avatar color="success" size="36" v-if="!item.image" />
          </td>
          <td class="body-2">
            <v-list-item-title>
              <div class="body-2">
                {{ `${item.email}` }}
              </div>
            </v-list-item-title>
          </td>
          <td class="body-2">
            <v-list-item-title>
              <div class="body-2">
                {{ `${item.firstname} ${item.lastname}` }}
              </div>
            </v-list-item-title>
          </td>
          <td class="body-2">
            <v-progress-linear
              color="success"
              rounded
              height="20"
              :value="item.progress"
              stream
            >
              {{ Math.ceil(item.progress) }}%
            </v-progress-linear>
          </td>
          <td class="body-2">
            <span
              @click="
                $router.push({
                  name: 'Teacher Student Progress',
                  params: { uuid: item.uuid },
                })
              "
              class="info--text"
              >View</span
            >
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: ["data"],
  methods: {
    view(id) {
      console.log(id);
    },
  },
};
</script>